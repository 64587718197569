/*BOX*/
.box {
  background: $grey-ultralight;
  padding: 20px 20px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin-bottom: 60px;
  padding-bottom: 6em;
  article {
    padding: 0;
    margin: 0;
    background: none;
  }
  h2.headline {
    border-bottom: 5px solid transparentize($grey-darkest, .85);
    line-height: 1;
    text-transform: uppercase;
    padding: 0 0 20px 0;
    margin: 0 0 20px;
    font-size: 1.25em;
    color: $font;
    text-align: left;
    span {
      font-size: 2.8rem;
    }
  }
  p {
    font-size: 1.45em;
    line-height: 1.45;
    margin: 0 0 1em 0;
  }

  a.link {
    border-top: 1px solid transparentize($grey-darkest, .85);
    display: block;
    height: 5em;
    position: absolute;
    bottom: 0;
    padding: 1.5em 0 0 0;
    left: 20px;
    width: calc(100% - 40px);
    text-align: center;
    color: $font;
    span {
      display: block;
      font-size: 1.85em;
      line-height: 1;
      font-weight: 800;
    }
    &:hover {
      color: $ci-color_highlight;
    }
  }
  .content a.link {
    height: auto;
    position: static;
    padding: 0;
    left: auto;
    width: 100%;
  }
  .infobox {
    background: $ci-color_primary;
    color: #fff;
    padding: 25px 30px 30px;
    margin: -25px -30px -30px;
    p {
      font-size: 2.8rem;
    }
    > * {
      color: #fff !important;
    }
    .btn {
      background: #fff;
      color: $font;
      &:before {
        background: $font;
      }
      &:hover, &:focus {
        color: #fff;
      }
    }
    &:after {
      position: absolute;
      content: url("../Images/info.svg");
      display: block;
      width: 46px;
      height: 46px;
      right: 0;
      top: 0;
      transform: translate3D(50%, -50%, 0);
    }
    ul li {
      list-style-image: url(../Images/li-bullet_white.gif);
    }
  }
  &.image {
    padding-bottom: 20px;
  }
  &.imageTeaser {
    display: block;
    background: #000 !important;
    padding: 0;
    h2.headline {
      text-shadow: 0 0 6px transparentize(#000, .3);
      padding: 0 20px;
      position: absolute;
      z-index: 1;
      border: none;
      width: 100%;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      span {
        font-size: 4em;
      }
      .subTitle {
        display: block;
        margin-top: .5em;
        font-weight: 800;
        font-family: $font-base;
        font-size: 1.4em;
        text-transform: none;
          line-height: 1.15;
      }
    }
    img {
      position: relative;
      transition: $t-medium;
      opacity: 1;
      width: 100% !important;
      height: auto !important;
    }
    &:hover, &:focus {
      img {
        opacity: .85;
      }
    }
  }
}

.tiles .box:not(.imageTeaser) {
  h2.headline {
    padding: 0 0 12px 0;
    margin: 0 0 15px;
  }
  padding: 0;
  padding-top: 75%;
  .outer {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 15px 20px;
  }
}

aside {
  .box {
    background: rgba(255, 255, 255, 0.75);
    padding: 30px;
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/
@media only screen and (max-width: 1399px) {
  .box {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1280px) {
  .box {
    p {
      font-size: 1.4em;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 1023px) {
  .box {
    a.link {
      position: static;
      margin-top: 2em;
    }
  }
  .tiles .box:not(.imageTeaser) {
    h2.headline {
    }
    padding: 0;
    .outer {
      position: relative;
      padding-bottom: 0;
    }

  }
}



/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: $screen-sm-max) {
  .tiles h2, .tiles .h2, .eventSlider h2, .eventSlider .h2 {
    margin-bottom: 0.1em;
    font-size: 5em;
    + p {
      margin-bottom: 2em;
      margin-top: 0;
      padding-top: 0;
      font-size: 1.6rem;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 1280px) {
  .box {
    margin-bottom: 3em;
    padding: 15px;
    h2, .h2 {
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 400px) {
  .box.imageTeaser h2.headline{
    font-size: 1.2em;
  }
  .tiles, .eventSlider {
    h2, h2.headline {
      margin-bottom: 0.1em;
      font-size: 4.4em;
      + p {
      }
    }
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: $screen-xs) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: $screen-sm) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: $screen-md) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: 1800px) {
  .box p {
    font-size: 1.8em;
  }
}

@media only screen and (min-width: 1920px) {
  .box p {
    font-size: 2em;
  }
}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/
