
.intext{
    padding: 0 15px;
  display: flex;
  flex-direction: column;
  [class*="col-"]{
    padding: 0;
    order: -1;
  }
    @media all and (min-width: 1000px){
      display: block;
    &.intext-left{
      [class*="col-"]{
        padding-left: 0;
        padding-right: 30px;
      }
    }
    &.intext-right{
      [class*="col-"]{
        float: right;
        padding-left: 30px;
        padding-right: 0;
      }
    }
  }
}

