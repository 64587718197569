.col3Box{
  margin: 5rem 0 0;
  .box{
    background: #fff;
    .content{
      font-size: 1rem;
      font-family: $font-base;
      a{
        display: block;
        position: relative;
        &:hover, &:focus{
          color: $ci-color_primary;
        }
      }
    }
    .sumbit-box{
      text-align: right;
    }
    form{
      button,input{
        transition: $t-fast;
        &[type="submit"]{
          display: inline-block;
          color: $ci-color_primary;
          margin: 0;
          padding: 0;
          border: none;
          outline: none;
          background: none;
          height: auto;
          font-size: 1.8rem;
          font-weight: 600;
          letter-spacing: 0.025em;
          &:hover, &:focus{
            color: $grey;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 999px) {
  .col3Box{
    //margin-bottom: 3.5rem;
    .box{
      margin-bottom: 1.5rem;
    }
  }
}