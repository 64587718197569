/*FORMS ETC*/
.form-control{
    font-size: 18px;
    line-height: 1;
    height: 40px;
    display: block;
    width: 100%;
    option{
        width: 100%;
    }
}
form {
  .form-group {
    margin-bottom: 5px;
  }
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $grey-light;
    font-weight: normal;
    font-size: 18px;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $grey-light;
    opacity: 1;
    font-weight: normal;
    font-size: 18px;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $grey-light;
    opacity: 1;
    font-weight: normal;
    font-size: 18px;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $grey-light;
    font-weight: normal;
    font-size: 18px;
  }
  .radio, .checkbox {
    label {
      margin-right: 15px;
    }
  }
  label {
    font-weight: normal;
    font-family: $font-base;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  .form-group {
    margin-bottom: 20px;
  }
  input {
    transition: $t-medium;
    &[type="radio"], &[type="checkbox"] {
      margin-top: 0;
    }

    &[type="text"], &[type="password"], &[type="email"] {
      font-size: 18px;
      line-height: 1;
      height: 40px;
      display: block;
      width: 100%;
      padding: 10px 7px;
      background: #fff;
      color: $grey-darker;
      border: 1px solid $grey-light;
      outline: none;
      margin-bottom: 10px;
      &:focus {
        border-color: $font;
        color: $font;
        box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.15);
        background: $grey-ultralight;
      }
    }
  }
  button, input {
    &[type="submit"] {

    }
  }
  textarea {
    &.form-control {
      font-size: 18px !important;
      color: $grey-darker;
      &:focus {
        border-color: $font;
        color: $font;
        box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.15);
        background: $grey-ultralight;
      }
      &#kontaktformular-message{
        height: 25rem;
      }
      &#kontaktformular-1155-message{
        height: 25rem;
      }
      &#kontaktformularrestaurant-message {
        height: 25rem;
      }
      &#kontaktformularrestaurant-1202-message {
        height: 25rem;
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media screen and (max-width: 999px){
  .col-md-6{
    form{
      .col-md-6{
        width: 100%;
        + .col-md-6{
          margin-top: 0;
        }
      }
    }
  }
}
