$font-path: "../fonts/golf-icons" !default;
@font-face {
  font-family: 'golf-icon';
  src: url('#{$font-path}/golf-icon.woff2?2bihcjddd') format('woff2'),
  url('#{$font-path}/golf-icon.ttf?2bihcjddd') format('truetype'),
  url('#{$font-path}/golf-icon.woff?2bihcjddd') format('woff'),
  url('#{$font-path}/golf-icon.svg?2bihcjddd#golf-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*="golf-icon "] {
  font-family: 'golf-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: relative;
  &:before{
    vertical-align: middle;
    display: inline-block;
  }
  span {
    font-family: $font-headlines;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
}

.golf-icon.bubble:before {
  content: "\e900";
}

.golf-icon.burger:before {
  content: "\e901";
}

.golf-icon.burger-close:before {
  content: "\e902";
}

.golf-icon.doc:before {
  content: "\e903";
}

.golf-icon.facebook:before {
  content: "\e904";
}

.golf-icon.fb-like:before {
  content: "\e905";
}

.golf-icon.globe:before {
  content: "\e906";
}

.golf-icon.hook:before {
  content: "\e907";
}

.golf-icon.info:before {
  content: "\e908";
}

.golf-icon.language:before {
  content: "\e909";
}

.golf-icon.link:before {
  content: "\e90a";
}

.golf-icon.login:before {
  content: "\e90b";
}

.golf-icon.phone:before {
  content: "\e90c";
}

.golf-icon.plus:before {
  content: "\e90d";
}

.golf-icon.share:before {
  content: "\e90e";
}

.golf-icon.sun:before {
  content: "\e90f";
}

.golf-icon.temp:before {
  content: "\e910";
}

.golf-icon.twitter:before {
  content: "\e911";
}

.golf-icon.youtube:before {
  content: "\e912";
}

.golf-icon.zoom:before {
  content: "\e913";
}

.golf-icon.golf-ball:before {
  content: "\e914";
}

.golf-icon.golfer_putting:before {
  content: "\e915";
}

.golf-icon.golfschlaeger:before {
  content: "\e916";
}

.golf-icon.golfer_swinging:before {
  content: "\e917";
}

.golf-icon.golf_flag01:before {
  content: "\e918";
}

.golf-icon.golf-flag:before {
  content: "\e919";
}

.golf-icon.golf-flag02:before {
  content: "\e91a";
}
.golf-icon.mail:before {
    content: "\e91b";
}
.golf-icon.navigation:before {
    content: "\e91c";
}
.golf-icon.mapMarker:before {
    content: "\e91d";
}
