article {
    position: relative;
    padding: 5em 0;
    &:nth-child(odd) {
        background: rgba(68, 65, 60, 0.07);
    }
    article {
        padding: 0;
        background-color: transparent !important;
    }
    &:first-child{
        padding-top: 10em;
    }
}

main + article {
    padding-top: 0;
}

.twoCol {
    article, section {
        //background: none !important;
        padding: 2em;
        padding-bottom: 0;
        margin-bottom: 5em;
    }
}

aside {
    .box {
        article {
            background: none !important;
            padding: 0;
        }
    }
}
