/*Main Navigation*/
nav ul li a:before {
  //display: none;
}

.mainnav {
  background: $ci-color_greyLight;
  position: relative;
  font-family: $font-base;
  border-bottom: 6px solid $font;
  border-left: none;
  border-right: none;
  .language {
    display: none;
  }
  .container {
    width: 100% !important;
    padding: 0 !important;
    max-width: none;
  }
  nav {
    text-align: center;
    display: table;
    width: 100%;
    ul:not(.layernav) {
      display: table-cell;
      width: 50%;
      margin-right: 0;
      vertical-align: middle;
      &.leftnav {
        text-align: right;
        padding-right: calc((#{$logowidth} / 2) + (#{$logowidth} / 10))
      }
      &.rightnav {
        text-align: left;
        padding-left: calc((#{$logowidth} / 2) + (#{$logowidth} / 10))
      }
    }
    ul:not(.layernav) {
      > li {
        display: inline-block;
        padding: 0;
        position: relative;
        margin: 0;
        > a {
          position: relative;
          color: $font;
          font-size: 1.75rem;
          padding: .75em 0;
          margin: 0 .75em;
          display: block;
          font-weight: 500;
          &:after {
            transition: $t-medium;
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -6px;
            height: 6px;
            background: $ci-color_highlight;
            width: 0;
            opacity: 0;
          }
          &:hover, &:focus {
            &:after {
              opacity: 1;
              width: 100%;
            }
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &.active {
          > a {
            font-weight: 700;
            ///background: $ci-color_greyLight;
            &:after {
            }
          }
          &.layernav-open {
            > a {
              color: $ci-color_primary;
              &:after {
                bottom: -4px;
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}
.topHeader {
  position: fixed;
  background: url(../Images/tafelBG.jpg);
  z-index: 10;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1920px;
}
.layernav {
  cursor: pointer;
  width: 30rem;
  max-width: 100vW;
  background: url(../Images/tafelBG_bright.jpg);
  background: $grey-darkest;
  border: none;
  padding: 0 20px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 50%);
  transition: $t-fast;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.19);
  text-align: center;
  > li {
    a {
      display: block;
      font-size: 1.8rem;
      font-weight: 600;
      padding: 1.3rem 0;
      border-bottom: 1px solid transparentize($grey-light, .7);
      color: $grey-light;
      &:hover, &:focus {
        color: #fff;
      }
    }
    &:last-child {
      a {
        border: none;
      }
    }
    &.active {
      a, a:hover {
        color: #fff !important;
      }
    }
  }
  &:before {
    content: "";
    background: url("../Images/arrow_layernav.svg") no-repeat center bottom -1px;
    width: 28px;
    height: 18px;
    display: block;
    position: absolute;
    left: 50%;
    top: -18px;
    transform: translate3D(-50%, 0, 0) scale(.6);
    transform-origin: center bottom;
  }
  .layernav-open & {
    top: 100% !important;
    transform: translate(-50%, 0%);
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.mainnav li:first-child {
  .layernav {
    left: 0;
    transform: none;
    &:before {
      left: 2.5rem;
      transform: none;
    }
  }
}

.hamburger {
  display: none;
}

.logo, .hamburger {
  transition: $t-fast;
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/
@media only screen and (max-width: 1475px) {
  .mainnav nav ul:not(.layernav) > li > a {
    margin: 0 .5em;
  }
}

@media only screen and (max-width: 1280px) {
  .mainnav nav ul:not(.layernav)>li>a {
    font-size: 1.6rem;
  }

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 1100px) {
  .mainnav nav ul:not(.layernav) > li.divider {
    padding: 30px 2rem;
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 1024px) {
  header {
    padding-top: 100px !important;
  }
  .hamburger {
    cursor: pointer;
    display: block;
    top: 12px;
    background: transparent;
    position: absolute;
    z-index: 100;
    right: 30px;
    text-align: center;
    svg {
      transition: $t-fast;
      display: block;
      transform: scale(1.2);
      + svg {
        display: none;
      }
      .navOpen & {
        display: none;
        + svg {
          display: block;
        }
      }
    }
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
  .mainnav.top {
    position: relative;
    width: 100%;
    z-index: 99;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    float: none;
    min-height: 48px;
    nav {
      display: block;
      margin: 0;
      padding: 50px 50px 50px 50px;
      position: absolute;
      z-index: 1;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: $t-slow;
      right: 0;
      top: 48px;
      width: 100vW;
      height: calc(100vH - 100px);
      background: url(../Images/tafelBG.jpg);
      transform: translateX(-100%);
      .navOpen & {
        transform: translateX(0%);
        transition: $t-slow;
      }
      ul {
        display: block;
        padding: 0 30px !important;
        width: 100%;
        margin-right: 0;
        vertical-align: middle;
        transform: none !important;
      }
      ul:not(.layernav) > li {
        padding: 0.8rem;
        display: block;
        text-align: center;
        margin: 0;
        border-bottom: 2px solid transparentize($grey-light, .85);
        float: none;
        &.divider {
          display: none;
        }
        > a {
          font-size: 3em;
          padding: 0.75em;
        }
        a {

          color: $ci-color_greyLight;
          &:before, &:after {
            display: none;
          }
          &:hover {
            color: $ci-color_highlight;
          }
          &:focus {
            color: $ci-color_highlight;
          }
        }
        &.active {
          > a {
            color: $ci-color_primary;
          }
        }
        //&:last-child{border: none}
      }
    }
  }
  .navOpen {
    .logo, .hamburger {
    }
  }
  .layernav {
    position: static;
    opacity: 1;
    visibility: visible;
    width: 100%;
    transform: none;
    box-shadow: none;
    background: none;
    border: none;
    &:before {
      display: none;
    }
    li {
      border: none;
      a {
        font-size: 2em;
        border: none;
        font-family: $font-base;
      }
      &.active {
        a, a:hover {
          color: #fff !important;
          font-family: $font-base;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 1024px-------------------------------------------------*/

@media only screen and (max-width: 1023px) {
  .language {
    display: none;
  }
  .mainnav.top {
    .language {
      width: 100%;
      display: block;
      margin-top: 3em;
      height: auto;
      a {
        width: calc(9% - .5em);
        margin: 0 .5em;
      }
    }
  }
}
@media all and (max-width: 999px){
    .hamburger{
        right: 25px;
    }
}
@media only screen and (max-width: 767px) {
  .mainnav.top {
    nav {
      padding: 50px 0 50px 0;
    }
    .language{
      padding: 0 30px;
    }
  }
}

@media only screen and (max-width: 479px) {
  .mainnav.top .language a {
    width: calc(15% - .5em);
    margin: 0 .5em 0.5em;
  }
  .mainnav.top nav {
    ul:not(.layernav) {
      > li {
        padding: 15px 0;
        a {
          font-size: 24px;
          padding: 10px 0;
          color: #fff;
        }
      }
    }
    .layernav li {
      padding: 0;
      a {
        font-size: 16px !important;
        padding: 6px 0 !important;
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 376px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: $screen-xs) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: $screen-sm) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: $screen-md) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/