/*FOOTERT*/

footer {
  position: relative;
  background: url("../Images/tafelBG_brighter.jpg");
  padding-top: 0;
  z-index: 149;
  &:after {
    content: "";
    display: block;
    background: #000000;
    opacity: 0.35;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }
  .footerImg {
    border: 8px solid $font;
    border-left: none;
    border-right: none;
    figure {
      background: #fff;
      padding: 4px 0;
      img {
        display: block;
      }
    }
  }
  * {
    z-index: 2;
    position: relative;
  }
  .logo {
    width: 9.5em;
    &:after{
      content: url(../Images/scrollTop.svg);
    }
  }
  .mainnav {
    background: none;
    position: relative;
    z-index: 2;
    border: none;
    margin: 12em 0;
    nav {
      ul:not(.layernav) li {
        a {
          color: $font;
          font-weight: 500;
          &:hover, &:focus {
            color: #fff;
          }
        }
        &.active {
          a, a:hover {
          }
        }
      }
    }
  }
  .meta {
    text-align: center;
    z-index: 2;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    transform: translateY(-250%);
    color: $font;
    nav {
      display: block;
      width: 100%;
      ul li {
        display: inline-block;
        font-size: 1.5rem;
        line-height: 1;
        a {
          display: block;
          padding: 5px 0;
          font-size: 1.5rem;
          line-height: 1;
          color: $font;
          font-weight: 500;
        }
      }
    }
  }
  .bottom {
    position: relative;
    text-align: center;
    width: 100%;
    bottom: 6%;
    z-index: 3;
    background: none;
    padding: 0;
    height: auto;
    line-height: 1;
    color: #fff;
    font-weight: 600;
  }
  .copyright {
    display: block;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    text-align: center;
  }
  #goTop {
    cursor: n-resize;
    width: 6.8rem;
    height: 6.8rem;
    border-radius: 6.8rem;
    background: $ci-color_primary;
    border: 1.1rem solid #fff;
    position: absolute;
    left: 50%;
    margin-left: -3.4rem;
    top: -3.4rem;
    transition: $t-fast;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3D(-50%, -55%, 0);
      width: 1.8rem;
      transition: $t-fast;
    }
    &:hover, &:focus {
      border-width: 0.7rem;
      img {
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1024px) {
  footer {
    .mainnav{
      nav{
        display: none;
      }
    }
    .logo {
      width: 6.5em;
    }
    .copyright{
      position: static;
      transform: none;
      padding: 10px 0;
      color: $font;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/
@media only screen and (max-width: 768px) {
  footer .footerImg figure img {
    display: block;
    width: 160%;
    left: -9%;
  }
}

@media only screen and (max-width: 700px) {
  footer .meta nav ul li:last-child {
    position: absolute;
    width: 100%;
    transform: translateY(100%);
    left: 0;
    a {
      font-size: 13px;
    }
  }
}
@media only screen and (max-width: 700px) {
  footer {
    .meta {
      position: relative;
      .copyright {
        display: block;
        text-align: center;
        margin-bottom: 30px;
      }
      nav {
        float: none;
        display: block;
        text-align: center;
        width: 100%;
        ul {
          li {
            font-size: 15px;
            a {
              font-size: 15px
            }
            &:nth-last-child(2){
              display: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 415px) {
  footer {
    .copyright {
      font-size: 11px;
    }
    .meta {
      nav {
        float: none;
        display: block;
        text-align: center;
        margin-top: 0px;
        transform: translateY(30px);
        ul {
          li {

            &.divider {
            }
            a {

              font-size: 15px;
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: $screen-xs) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: $screen-sm) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: $screen-md) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/
