.zertifikate {
  .item {
    display: inline-block;
    margin-left: 10px;
    height: 7em;
    transition: $t-fast;
    img {
      height: 100%;
      width: auto;
    }
    &:last-child {
      img {
      }
    }
  }
}

header, .hero {
  .zertifikate {
    position: absolute;
    z-index: 5;
    right: 20px;
    bottom: 30px;
    text-align: right;
    .item {
      &:hover {
        transform: translateY(-10px);
      }
    }
  }
  &.subPage {
    .zertifikate {
      display: none;
    }
  }
}
footer .zertifikate {
  padding: 15px 10px;
  text-align: left;
  background: url(../Images/tafelBG.jpg) $font;
  .item {
    height: auto;
    max-width: 20%;
    width: 12em;
    img{
      width: 100%;
      height: auto !important;
    }
    &:last-child{
      float: right;
      img{
        //transform: scale(1.06);
      }
    }
  }
}

@media only screen and (max-width: $screen-sm-max) {
  header, .hero {
    .zertifikate {
      font-size: 1vW;
    }
  }
}


@media only screen and (max-width: 600px) {
  header, .hero {
    .zertifikate {
      text-align: center;
      font-size: 1.25vW;
      POSITION: relative;
      right: 0;
      bottom: 0;
      background: #fff;
      padding: 2em 0;
      background: $grey-light;
      .item {
        margin-bottom: 0 !important;
        transform: none;
        height: auto;
        width: calc(25% - 14px);
        img {
          width: 100%;
          height: auto;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

