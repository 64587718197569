/*EVENT MAP*/
#eventMap {
  margin-bottom: 50px;
}

.tx-amlocations {
  .location {
    cursor: pointer;
    a {
      display: block;
      background: $grey-ultralight;
      border: 1px solid $grey-light;
      font-size: 2.8rem;
      line-height: 1;
      padding: 20px 30px;
      margin: 0 0 30px 0;
      transition: $t-fast;

      .zip {
        display: block;
        color: $font;
        margin-bottom: 5px;
      }
      .city {
        display: block;
        color: $ci-color_primary;
        font-weight: 600;
      }
      &:hover {
        border-color: $grey;
        background: #fff;
      }
    }
  }
  .locations-detail {
    .address {
      margin-bottom: 3rem;
      p {
        margin-bottom: 0;
      }
    }
    .opening-time {
      font-size: 1.6rem;
      display: inline-block;
      .item {
          margin-bottom: 1rem;
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: $screen-lg) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: $screen-md-max) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: $screen-sm-max) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: $screen-xs-max) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 600px) {
  .tx-amlocations {
    .col-xs-6 {
      width: 100%;
      float: none;
    }
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: $screen-xs) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: $screen-sm) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: $screen-md) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/