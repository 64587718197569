.gallery {
  [class*="col-"].img {
    overflow: hidden;
    margin-bottom: 60px;
    a {
      position: relative;
      box-sizing: border-box;
      display: block;
      width: 100%;
      &:before{
        transition: $t-medium;
        box-sizing: border-box;
        content: "";
        position: absolute;
        left: 0;
        top:0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0 solid transparentize($ci-color_highlight, 1);
        background: transparentize(#000, 1);
      }
      &:after{
        transition: $t-medium;
        display: block;
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%, -50%) scale(.75);
        content: attr(data-caption);
        font-family: $font-headlines;
        font-size: 4em;
        color: #fff;
        opacity: 0;
      }
      img{
      }
    }
    &:hover, &:focus {
      a{
        &:before{
          border: 10px solid $ci-color_highlight;
          background: transparentize(#000, .75);
        }
        &:after{
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}

@media all and (max-width: 600px){
  .gallery .col-md-4.img a:after{
   font-family: $font-base;
    font-size: 12px;
    font-weight: 600;
  }
}
@media all and (max-width: 420px){
  .gallery .col-md-4.img {
    width: 100%;
    margin-bottom: 20px;
    a:after{
      font-family: $font-headlines;
      font-size: 18px;
      font-weight: normal;
    }
  }
}