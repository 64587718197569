/*WEBFONTS*/


$font-headlines: 'Bebas Neue W01 Regular', sans-serif;
$font-base: 'Raleway', sans-serif;

body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
  font-family: $font-base;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  margin-top: 0;
}

h1, .h1, h2, .h2 {
  font-size: 5.3rem;
  font-family: $font-headlines;
  padding-bottom: .5em;
  margin-bottom: .5em;
  border-bottom: 0.11em solid $font;

}
article, section{
  > .container{
    /*> h1{
      font-size: 5.6rem;
       padding: 0;
       border:none;
       margin-bottom: .8em;
     }*/
  }
  > h1{
    font-size: 5.6rem;
    padding: 0;
    border:none;
    margin-bottom: .8em;
  }
}

h2, .h2 {
  font-size: 5.3rem;
  font-family: $font-headlines;
  line-height: 1;
  margin-bottom: 0.5em;
}
h3, .h3, main section h3, main article h3 {
  font-size: 2.8rem;
  font-weight: 700;
  font-style: italic;
  line-height: 1.31;
  margin-top: 3em;
}
main aside, main aside article{
    h3{
        margin-top: 1em;
        margin-bottom: .5em;
    }
    h4{
        margin-top: 1.75em;
        margin-bottom: 1em;
        font-size: 1.8rem;
        line-height: 1.2;
    }
    h5{
        font-size: 1.4rem;
        margin-top: 2em;
        line-height: 1.2;
        margin-bottom: 1em;
    }
    h6{
        font-size: 1.2rem;
        margin-top: 2em;
        line-height: 1.2;
        margin-bottom: 1em;
    }
}
h4, .h4 {
  font-size: 2.0rem;
}

h5, .h5 {
  font-size: 1.6rem;
}

h6, .h6 {
  font-size: 1.2rem;
}

h1, .h1, h2.h1 {
  font-weight: normal;
}

h2, .h2 {
  span {
    display: block;
  }
}

h1, .h1, h2, .h2 {
}

h4, .h4, b, strong {

}

p{
  &:empty
  {display: none;}
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top:50px;
  a {
    &:hover, &:focus, &:active {

    }
  }
  main & {
  }
  section &, article &{
    margin-top:0;
  }
}
h1, .h1, h2, .h2{
  em, i{
    font-style: normal !important;
  }
  strong, bold{
    font-weight: normal !important;
  }
}
[class*="col-"], h1, h2, .h1, .h2{
  > h3, > .h3{
    margin-top: 0;
  }
}
h1, h2, .h1, .h2{
  + h3, + .h3{
    margin-top: 0;
  }
}
nav {
  ul, ul li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

a {
  color: $font;
  text-decoration: none;
  transition: $t-fast;
  &:hover, &:focus{
    color: $ci-color_primary;
  }
}

main {
  a {
    text-decoration: none;
    transition: $t-fast;
    &:hover, &:focus, &:active {
      h1, .h1, h2, .h2 {
      }
    }
    h1, .h1, h2, .h2, p {
      text-decoration: none;
    }
  }

  p, ol li, ul li {
    b, strong {
      font-family: $font-base;
    }
  }
  p, .text, address, ul li, ol li {
    font-size: 2rem;
    line-height: 1.6128;
    line-height: 1.45;
    font-weight: normal;
    font-style: normal;
    strong, b{
      font-weight: 600;
    }
    &.intro, &.big {
      font-size: 2rem;
    }
    &.small {
      font-size: 1.4rem;
    }
  }
  figure {
    margin: 0 0 2rem;
  }
  p, ul li, ol li {
    margin: 0 0 3rem;
    &:last-of-type {
    }
    a {
      transition: $t-fast;
      text-decoration: none;
      position: relative;
      color: $font;
      font-weight: 500 !important;
      font-style: normal;
      border-bottom: 1px solid $ci-color_highlight;
      &:before {
        transition: $t-fast;
        position: absolute;
        //display: block;
        //content: "";
        height: 1px;
        background: $ci-color_primary;
        left: 0;
        bottom: -3px;
        width: 100%;
      }
      &:hover, &:focus, &:active {
        color: $ci-color_primary;
        &:before {
          background: $grey-light;
        }
      }
    }
  }
  table a, table p a{
    border: none;
  }
  ul, ol {
    margin: 0 0 4rem;
    padding: 0 0 0 41px;
    nav & {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        margin: 0;
        a {
          color: $grey;
          text-decoration: none;
          &:hover, &:focus {
            color: $ci-color_primary !important;
          }
        }
      }
    }
  }
  ul {
    li {
      list-style-image: url("../Images/li-bullet_green.png");
      list-style-position: outside;
      margin-bottom: 1rem;
      &:last-of-type {
        //margin-bottom: 0;
      }
    }
  }
  ol{
    list-style-type: none;
    padding-left: 0;
    li{
      counter-increment: step-counter;
      position: relative;
      padding-left: 45px;
      &:before {
        position: absolute;
        left: 0;
        content: counter(step-counter);
        margin-right: 5px;
        font-size: 100%;
        background-color: $font;
        color: white;
        padding: 1px 8px;
        border-radius: 3px;
        font-family: $font-headlines;
        min-width: 16px;
        text-align: center;
      }
    }
  }
  .text {
    text-align: left;
  }

  .text-center {
    text-align: center;
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, p {
      text-align: center;
    }
    h1, .h1, h2, .h2 {
    }
    h2, .h2 {
    }
    p:last-child {

    }
  }
}
aside{
  h2, .h2{
    font-size: 3.6rem;
  }
}


//END main
hr {
  margin: 0;
  border: none;
  height: 0;
  border-top: 1px solid #ccc;
  padding: 10px;
}

blockquote {
  font-size: 3.6rem;
  font-weight: normal;
}

.tiles, .eventSlider{
  h1, .h1, h2, .h2{
    text-align: center;
    color: $ci-color_highlight;
    border: none;
    padding-bottom: 0;
    margin-bottom: 0.4em;
    font-size: 6rem;
    + p{
      text-align: center;
      color: #fff;
      margin-bottom: 2.5em;
      margin-top: -2.4em;
    }
  }
}
.tiles{
    h1, .h1, h2, .h2{
        padding-top: .4em;
    }
}


/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  h1, .h1, h2, .h2 {
    font-size: 4.6rem;
    &.icon {
      img {
      }
    }
    br {
      display: none;
    }
  }
  blockquote {
    font-size: 1.4rem;
  }
  main{
    p, .text, address, ul li, ol li {font-size: 1.8rem}
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/
/* Extra Small Devices, Phones */
@media only screen and (max-width: 479px) {

  h1, .h1, h2, .h2 {
    hyphens: auto;
  }
  h2, .h2 {
    hyphens: auto;
    br {
      display: none;
    }
  }
  h3, .h3, main section h3, main article h3 {
    font-size: 2.2rem;
  }
  .tiles, .eventSlider{
      h1, .h1, h2, .h2{
      + p{
        margin-bottom: 2.5em;
        margin-top: 0;
      }
    }
  }
  h1, .h1 {
    font-size: 3.8rem
  }
  h2, .h2 {
    font-size: 3.8rem;
    margin-bottom: 0.7em;
    padding-bottom: 0.7em;
  }
  h3, .h3 {
    font-size: 2.4rem
  }
  h4, .h4 {
    font-size: 1.8rem
  }
  h5, .h5 {
    font-size: 1.5rem
  }
  h6, .h6 {
    font-size: 1.2rem
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

/* Custom, iPhone Retina */
@media only screen and (max-width: 374px) {
  h1, .h1, h2, .h2 {
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/





/* bebas-neue-regular - latin */
@font-face {
  font-family: 'Bebas Neue W01 Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/bebas-neue-v1-latin/bebas-neue-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Bebas Neue Regular'), local('BebasNeue-Regular'),
  url('../fonts/bebas-neue-v1-latin/bebas-neue-v1-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/bebas-neue-v1-latin/bebas-neue-v1-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/bebas-neue-v1-latin/bebas-neue-v1-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/bebas-neue-v1-latin/bebas-neue-v1-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/bebas-neue-v1-latin/bebas-neue-v1-latin-regular.svg#BebasNeue') format('svg'); /* Legacy iOS */
}

/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Raleway Light'), local('Raleway-Light'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-300italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-300italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Italic'), local('Raleway-Italic'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Raleway Medium'), local('Raleway-Medium'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-500italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-600italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold'), local('Raleway-Bold'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/raleway-v14-latin/raleway-v14-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'),
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v14-latin/raleway-v14-latin-700italic.svg#Raleway') format('svg'); /* Legacy iOS */
}

