
.eventSlider_outer {
  position: relative;
}

.eventSlider {
  h1, .h1 {
    font-size: 6rem;
    font-family: $font-headlines;
    color: $ci-color_highlight;
    text-align: center;
    + p {
      text-align: center;
      color: #fff;
      margin-top: -2em;
      margin-bottom: 4em;
    }
  }

  &.col3Box {
    position: relative;
    background: url(../Images/tafelBG.jpg);
    margin-top: -1px;
    padding: 5em 0 7em;
    padding-top: 2.5em !important;
    .container {
      position: relative;
    }
    .slide {
      opacity: 0;
      .box {
        h2, .h2 {
          color: $font;
          text-align: left;
        }
        background: none;
        position: relative;
        z-index: 2;
        margin: 0;
        padding-bottom: 10px;
        transform: scale(.8);
        transition: all .2s;
        &:before {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          content: "";
          background: url(../Images/tafelBG_brighter.jpg);
          opacity: .82;
          z-index: -1;
        }
        h2.headline {
          margin: 0 0 2px 0;
          span {
            font-size: 3.75rem;
          }
        }
        .content.scrollable {
          height: 33em;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          font-size: 1.25em;
          table {
            font-size: 1.5em;
            font-weight: 400;
            line-height: 1.25;
            p {
              margin: 0;
              font-size: 1em;
              line-height: 1.25;
              font-weight: 500;
              color: $font;
            }

            tr {
              border-bottom: 1px solid rgba(46, 43, 44, 0.15);
              &:first-child, &:last-child {
                border: none;
              }
              td {
                padding: .6em 0;
                &.tag {
                  font-weight: 600;
                  &:not(:empty) {
                    width: 2em;
                  }
                }
                &.datum {
                  font-weight: 600;
                  min-width: 3.5em;
                }
                &.event {

                }
              }
            }
          }
        }
      }
    }
    .slick-initialized {
      .slide {
        opacity: 1;
        transition: all 0.3s;
      }
    }
    .slick-slide {
      opacity: .7;
      &.slick-active {
        transition: all 0.3s;
        opacity: .7;
        .slide .box .content.scrollable table {
          font-size: 1.1em;
        }
        &.slick-center {
          transition: all .3s;
          opacity: 1;
          .box {
            transform: scale(1);
          }
        }
        + .slick-slide.slick-active {
          opacity: 1;
          .box {
            transform: scale(1);
          }
          + .slick-slide.slick-active {
            opacity: .7;
            transform: scale(.8);
          }
        }
        &:hover {
          transition: all 0.5s;
        }
      }
    }
  }
}

.foreground {
  position: relative;
  z-index: 10;
}

.silhouettes {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  .golfer {
    max-height: 75%;
    position: absolute;
    z-index: 1;
    width: 16%;
    img {
      height: auto;
      width: 100%;
    }
    &.g01 {
      left: 1%;
      bottom: 5%;
      width: 24%;
    }
    &.g02 {
      left: 22%;
      top: 4%;
    }
    &.g03 {
      right: 18.5%;
      bottom: 3%
    }
    &.g04 {
      right: 1%;
      top: 15%;
      width: 19%;
    }
  }
}

@media screen and (min-width: 1281px) {
  .silhouettes {
    .golfer {
      &.g01 {
      }
      &.g02 {
        left: 25%;
      }
      &.g03 {
      }
      &.g04 {
      }
    }
  }
}

@media screen and (min-width: 1650px) {
  .eventSlider.col3Box{
    padding-bottom: 18em;
  }
}
@media screen and (max-width: 1400px) {
  .eventSlider .container {
    width: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  .silhouettes {
    .golfer {

      &.g01 {
        left: 0;
        width: 30%;
      }
      &.g02 {
        width: 20%;
        left: 25%;
        top: 3%;
      }
      &.g03 {
        width: 22%;
      }
      &.g04 {
        right: 0;
        width: 24%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .eventSlider.col3Box .slide .box .content.scrollable table {
    tr {
      td {
        display: inline-block;
        &.event {
          padding-top: 0;
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 969px) {
  .silhouettes {
    display: none;
  }
  .eventSlider.col3Box {
    h2 {
      margin-bottom: 0.6em;
    }
    .slick-slide.slick-active {
      opacity: 1;
    }
    .slide {
      .box {
        .content.scrollable {
          height: 450px;
          table {
            font-size: 3.2vW;
            td {
              display: inline-block;
              text-align: left;
              &.tag, &.datum {
                width: auto;
                margin-right: 5px;
                padding-bottom: 0;
                &:empty {
                  margin: 0;
                }
              }
              &:before {
                content: attr(data-label);
                display: none;
                float: left;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .eventSlider.col3Box .container {
    padding: 0 70px;
  }
}

@media screen and (max-width: 480px) {
  .eventSlider.col3Box .container {
    padding: 0 40px;
  }
  .eventSlider.col3Box .slide .box {
    h2.headline span {
      font-size: 3rem;
    }
    .content.scrollable {
      height: 50vH;
    }
  }
}
@media screen and (max-width: 345px) {
  .eventSlider.col3Box .container {
    padding: 0 40px;
  }
  .eventSlider.col3Box .slide .box {
    h2.headline span {
      font-size: 3rem;
    }
    .content.scrollable {
      height: 50vH;
    }
  }
}


