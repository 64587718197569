.tiles.col3Box {
  position: relative;
  background: url(../Images/tafelBG.jpg) $font;
  margin-top: 0;
  padding: 0 !important;
  .box{
    background: url(../Images/tafelBG_brighter.jpg);
  }
 h1, h2, h3, h4, h5 {
   &.grey {
     color: #DBDBDB;
   }
 }
}


@media only screen and (max-width: 999px) {
  .tiles.col3Box {
    .box:not(.imageTeaser){
      height: auto !important;
      &.image {
        padding-bottom: 20px;
      }
    }
  }
}
@media only screen and (min-width: 1651px) {
  .tiles.col3Box .box {
    font-size: 1.2em;
  }
}



.box.image img#frame {
  display: block;
  width: 100%;
  height: auto;
  @media (min-width: 1000px) {
    height: 12vw;
    margin: 0;
    object-fit: cover;
    object-position: bottom;
  }
  @media (min-width: 1920px) {
    height: 282px;
  }
}
