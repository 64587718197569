.ampel{
  margin-top: 1.5em;
  .status{
    display: inline-block;
    margin-right: 1em;
    .circle{
      width: 2.2em;
      height: 2.2em;
      border:2px solid #fff;
      border-radius:50%;
      background: #DADBDA;
      margin-right: 3px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .statusText{
    font-size: 1.1em;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
  }
  &.green{
    .status{
      .circle:nth-child(3){
        background: #43AD33;
      }
    }
  }
  &.yellow{
    .status{
      .circle:nth-child(2){
        background: #FFD700;
      }
    }
  }
  &.red{
    .status{
      .circle:nth-child(1){
        background: #E3010F;
      }
    }
  }

}