/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slide-left, .slide-right {
  font-size: 10px;
  width: 3em;
  height: 12em;
  line-height: 12em;
  border-radius:0;
  background: transparentize($ci-color_highlight, .25);
  text-align: center;
  position: absolute;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: $t-fast;
  &:before{
    content: "";
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top:50%;
    transform:translate(-50%, -50%);
  }
  &:hover{
    background: transparentize($ci-color_highlight, 0);
  }
}

.slide-left {
  left: 0;
  &:before{
    border-width: 6px 10px 6px 0;
    border-color: transparent $font transparent transparent;
    margin-right: 2px;
    left: 46%;
  }
  &:hover {
    &:before{
      //border-color: transparent #ffffff transparent transparent;
    }
  }
}


.slide-right {
  right: 0;
  &:before{
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent $font;
    left: 54%;
  }
  &:hover {
    &:before{
    }
  }
}

ul.slick-dots {
  position: absolute;
  z-index: 20;
  width: 100%;
  left: 0;
  bottom: -4em;
  text-align: center;
  padding: 0;
  margin: 0;
  li {
    cursor: pointer;
    margin: 0 5px;
    padding: 0;
    list-style: none;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: transparentize(#fff, .5);
    overflow: hidden;
    transition: $t-fast;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover, &:focus {
      transform: scale(1.25);
      background: #fff;
    }
    &.slick-active {
      background: $ci-color_highlight;
      cursor: default;
      transform: scale(1.25);
      &:hover, &:focus {
        transform: scale(1.25);
      }
    }
    button {
      display: none;
    }
  }
}

@media screen and (max-width: 1399px){
  .slide-left {
    left: 0;
  }
  .slide-right {
    right: 0;
  }

}
@media screen and (max-width: 767px){
  .eventSlider.col3Box .slick-slide, .eventSlider.col3Box .slide .box{
    opacity: 1 !important;
    transform:scale(1) !important;
  }
  .slide-left {
    //transform:scale(1);
    left: 0;
  }
  .slide-right {
    right: 0;
    //transform:scale(1);
  }
  .slide-left, .slide-right {
    //display: none;
  }
}

@media screen and (max-width: 480px){

}