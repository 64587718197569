/*HEADER*/
$logowidth: 9.5em;
header, footer {
    position: relative;
    background: url("../Images/tafelBG.jpg") $font;
    z-index: 150;
    .logo {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate3D(-50%, -50%, 0);
        width: $logowidth;
        &:before, &:after {
            box-sizing: border-box;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 3;
            display: block;
            left: 0;
            top: 0;
            border-radius: 50%;

            transform: scale(0);
        }
        &:before {
            transition: $t-fast;
            background: $grey-light;
            border: 6px solid $grey-darkest;
            transition-delay: .2s;
        }
        &:after {
            transform: scale(0);
            opacity: 0;
            content: url(../Images/icon_home.svg);
            z-index: 4;
            padding: 0;
            transition: $t-medium;
        }
        img {
            position: relative;
            z-index: 2;
        }
        &:hover, &:focus {
            &:before {
                transform: scale(0.6);
            }
            &:after {
                transform: scale(.3);
                transition-delay: .3s;
                opacity: 1;
            }
        }
        &:focus {
            &:before {
                background: $ci-color_highlight;
            }
        }
    }
    &.sub, .sub & {
    }

}
// Animate LOGO
header{
    .logo{
        opacity: 0;
        transform: translate3D(-50%, -50%, 0) scale(.25);
        transition: all .25s ease-out;
    }
    .mainnav nav{
        opacity: 0;
        transition: all .25s .25s ease-out;
    }
}

.hero-visible{
    header{
        .logo{
            opacity: 1;
            transform: translate3D(-50%, -50%, 0);
        }
        .mainnav nav{
            opacity: 1;
        }
    }
}
header {
    @media only screen and (min-width: 1920px) {
        padding-top: 186px !important;
    }
}

@mixin gradient_grey {
    background: #c0c6c8;
    background: -moz-linear-gradient(top, #c0c6c8 0%, #8c969a 50%);
    background: -webkit-linear-gradient(top, #c0c6c8 0%, #8c969a 50%);
    background: linear-gradient(to bottom, #c0c6c8 0%, #8c969a 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c0c6c8', endColorstr='#8c969a', GradientType=0);
}

.badge {
    display: block;
    position: absolute;
    right: 5%;
    top: 15%;
    width: 15rem;
    padding-top: 15rem;
    border-radius: 10rem;
    text-align: center;
    transform: rotate(12.5deg);
    transition: $t-fast;
    @include gradient_grey;
    &:before {
        transition: $t-fast;
        content: "";
        display: block;
        position: absolute;
        left: -1.2rem;
        top: -1.2rem;
        width: 16.4rem;
        height: 16.4rem;
        border: 0.5rem solid $ci-color_primary;
        border-radius: 10rem;
    }
    .table {
        position: absolute;
        left: 0;
        top: 0;
        width: 15rem;
        height: 15rem;
        padding: 0 1.8rem;
        .h1, .h2 {
            color: #fff;
        }
        .h1 {

            font-weight: 600;
            margin-bottom: 0.7rem;
            font-size: 2rem;
        }
        .h2 {
            font-size: 1.8rem;
        }
    }
    &:hover, &:focus {
        transform: scale(1.1);
        &:before {
            transform: scale(1.15);
        }
    }
}

.meta {
    font-size: 1.6rem;
    padding: 1.75em 4.5rem;
    margin: 0;
    position: relative;
    display: table;
    color: #fff;
    width: 100%;
    nav {
        display: table-cell;
        vertical-align: middle;
        width: 50%;
        a {
            display: inline-block;
            color: #fff;
            padding: 1.3rem 0;
            &:hover, &:focus {
                color: #fff;
            }
        }

        &.left {
            a {
                height: 2em;
                overflow: hidden;
                display: inline-block;
                vertical-align: middle;
                transition: $t-medium;
                color: $font;
                background: $ci-color_greyLight;
                padding: 0 0.4em;
                line-height: 2em;
                border-radius: 3px;
                position: relative;
                transition-duration: .2s;
                transition-timing-function: ease-out;
                &:hover, &:focus {
                    z-index: 10;
                    color: #fff;
                    transform: scale(1.1);
                    background: $ci-color_highlight;
                }
            }
            .facebook {
                &:before {
                    font-size: 1.4em;
                    display: block;
                }
            }
            .login {
                font-family: $font-headlines;
                &.contact {
                    .golf-icon {
                        display: none;
                    }
                }
            }
        }
        &.right {
            text-align: right;
        }
        .divider {
            display: inline-block;
            padding: 0 10px;
        }
    }
}

.language {
    display: inline-block;
    vertical-align: middle;
    width: 75%;
    a.flag {
        display: inline-block;
        margin-left: 0.75em;
        height: auto;
        width: calc(8% - 0.75em);
        float: none;
        opacity: .7;
        transition: $t-medium;
        img {
            display: block;
            height: auto;
            width: 100%;
        }
        &:hover, &:focus {
            opacity: 1;
            transform: scale(1.15);
        }
        &.active {
            opacity: 1;
        }
    }
}

.hero {
    position: relative;
    position: fixed;
    width: 100%;
    max-width: 1920px;
}
/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: $screen-lg) {
    .meta {
        padding: 10px 30px;
    }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 1024px) {
    header .logo, footer .logo {
        width: 15em;
    }
}

@media only screen and (max-width: 1340px) {
    header {
        .logo {
            width: 9.5em;
        }
    }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: $screen-sm-max) {
    header {
        .logo {
            width: 9.5em;
        }
        .hero {
        }
    }
    .meta {
        padding: 10px 15px;
        nav a {
            padding: 0;
        }
    }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/



/*END BREAKPOINT MAX 600px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
    header {
        .logo {
            width: 6.5em;
        }
    }
}


/*END BREAKPOINT MIN 992px-------------------------------------------------*/
@media only screen and (min-width: 1441px) {
    header {
        .claim {
            padding: 30px 0;
            h1 {
                font-size: 70px;
                span {
                    font-size: 35px;
                }
            }
        }
    }

}

.meta nav {
    .telefon {
        margin-left: .2rem;
        .nr {
            font-weight: 400;
            font-family:$font-headlines;
            font-size: 1em;
            padding: 0 .5em;
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
            transform: translateY(-.05em);
        }
    }
}

.meta nav {
    a.facebook, a.login, a.navigation {
        margin-right: 10px;
    }
    a.telefon, a.login.contact {
        margin: 0;
    }
    a.login.contact{
        border-radius: 3px 0 0 3px;
    }
    a.routing{
        border-radius: 0 3px 3px 0;
        margin-right: 10px;
        margin-left: 2px;
        padding: 0 0.5em;
        span{
            font-size: 1.25em;
            line-height: 1.35;
        }
    }
}

@media only screen and (max-width: 1023px) {
    .meta nav.left {
        width: 100%;
        .telefon, .contact, .routing {
            margin: 0;
            min-width: 2.2em;
            float: right;
            text-align: center;
            border-radius: 3px;
            .nr {
                display: none;
            }
        }
        .routing{
            margin-left: 10px;
        }
        .login {
            font-family: $font-headlines;
            &.contact {
                margin-left: 10px;
                border-radius: 3px;
                span {
                    display: none;
                }
                .golf-icon.mail {
                    font-size: 1.4em;
                    line-height: 1.5;
                    display: block;
                    vertical-align: top;
                    &:before {
                        display: block;
                    }
                }
            }
        }
    }
    .meta nav.right {
        display: none;
    }
}
@media only screen and (max-width: 767px) {
    .meta{
        font-size: 1.4rem;
    }
}
@media only screen and (max-width: 499px) {
    .meta{
        font-size: 1.2rem;
        nav a{
            &.facebook{
                margin-right: 5px;
            }
            &.routing, &.contact{
                margin-left: 5px !important;
            }
        }
    }
}

@media only screen and (max-width: 349px) {
    .meta{
        nav a{
            &.routing{
                display: none !important;
            }
        }
    }
}