


a.cookieconsent-optout-marketing {
  position: relative;
  display: block !important;
  color: inherit;
  text-decoration: none;

  &:hover {
    .cc-overlay {
      background: transparentize(#fff, 0.35);
    }

    .cc-previewImage {
      img {
      }
    }
  }
}

.cc-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: transparentize(#fff, 0.25);
  transition: all 0.35s;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.cc-videoIcon {
  fill: currentColor;
  fill-rule: evenodd;
  margin-bottom: 15px;
  opacity: 0.75;
  width: 10vw;
  max-width: 90px;
  min-width: 50px;
}

.cc-content {
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 400px;
  padding: 20px;

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;

    @media all and (max-width: 450px) {
      font-size: 12px;
      line-height: 1.35;
    }
  }
}


#CybotCookiebotDialogDetail{
  tr{
    background:none !important;
  }
}
.CookieDeclarationDialogText{
  display: none;
}

.CookieDeclarationIntro{
  display: none;
}

#CybotCookiebotDialogBodyContentTitle{
  font-size: 10pt !important;
}

#CybotCookiebotDialog br, #CybotCookiebotDialog div, #CybotCookiebotDialog td {
  line-height: 1 !important;
}


#CybotCookiebotDialogBody {
  max-width: 1200px !important;
  padding-right: 30px !important;
  padding-left: 30px !important;
  overflow: hidden;
}

#CybotCookiebotDialogDetailBody {
  max-width: 1128px !important;
}

