/*BUTTONS*/

.btn {
  font-family: $font-headlines;
  font-weight: normal;
  font-size: 2.8rem;
  display: inline-block;
  border: none;
  background: transparent;
  border:0.175em solid $font;
  border-left:none;
  border-right:none;
  padding: 0 2rem;
  text-align: center;
  min-width: 250px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 10;
  margin-top: 3rem;
  transition: $t-medium;
  color: $font;
  line-height: 1.618;
  border-radius: 0 !important;
  &.btn-small{
    font-size: 1.8rem;
    padding: 0.8rem 1.6rem;
    margin: 1.5rem 0;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &:hover, &:focus {
    color: #fff;
    background: $ci-color_primary;
    border-color: $ci-color_primary;
  }
  &.highlight{
    border-color: $ci-color_primary;
    &:hover, &:focus {
      color: #fff;
      background: $ci-color_primary;
      border-color: $ci-color_primary;
    }
  }
  &.orange{
    border-color: $ci-color_primary;
    background-color: $ci-color_primary;
    color: #fff;
    &:hover, &:focus {
      color: #fff;
      background: $font;
      border-color: $font;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  .btn {
    max-width: 100%;
    min-width: 100%;
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/