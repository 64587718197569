/*BASE*/
html {
  font-size: 12px;
}

body, html {
  height: 100%;
  min-height: 100%;
}

body {
  background: url("../Images/tafelBG_bright.jpg");
  background: url(../Images/tafelBG.jpg);
  background-attachment: fixed;
  color: $font;
  &.navOpen {
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vH;
    max-height: 100vH;
  }
}

#wrap {
  max-width: 1920px;
  margin: 0 auto;
  box-shadow: 0 0 51px 0px rgba(0, 0, 0, 0.6), 0 0 8px 0 rgba(0, 0, 0, 0.6);
  background: url("../Images/tafelBG_brighter.jpg") $font;
  overflow: hidden;
}

main {
  margin: 0;
  position: relative;
  z-index: 6;
  background: url(../Images/tafelBG_brighter.jpg);
  transition: margin-top .3s ease-out;
  > section, > article {
    padding-top: 5em !important;
      &:first-child{
          padding-top: 10em !important;
      }
  }
}

.mainContent {
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  &:focus {
    outline: none !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

figure, blockquote {
  margin: 0;
}

.row.centered {
  text-align: center;
  vertical-align: top;
  [class*="col-"] {
    text-align: left;
    margin-right: -4px;
    display: inline-block;
    vertical-align: top;
    float: none;
  }
}

.centered {
  text-align: center;
}

table {
  width: 100%;
}

table:not(.eventTable):not(.CookieDeclarationTable), main table:not(.eventTable):not(.CookieDeclarationTable) {
  width: 100%;
  background: #fff;
  font-size: 1em;
  tr {
    transition: $t-medium;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &:hover {
      background: rgba(0, 0, 0, 0.075);
    }
  }
  td {
    vertical-align: middle;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    &:first-child {
      border-left: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
  p {
    font-size: 1.4em;
    line-height: 1;
    text-align: center;
    margin: 10px 0;
  }
  > tbody {
    > tr:first-child {
      background: $ci-color_greyWarm !important;
      color: #fff;
      border: none;
      p {
        font-weight: 700;
        font-size: 1.2em;
      }
    }
  }
}

.table {
  display: table;
  .tableCell {
    display: table-cell;
    vertical-align: middle;
  }
}

.container .container {
  padding: 0 !important;
  width: 100% !important;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25% !important;
  padding-top: 25px;
  height: 0;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media (min-width: 1920px) {
  html {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1650px) {
  html {
    font-size: 0.725vW;
  }
}
@media only screen and (max-width: 1399px) {
  .container {
    max-width: 1130px;
  }
  [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
  .row {
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 0.8vW;
  }
}

@media only screen and (max-width: 1050px) {
  .row {
    margin: 0 -15px;
  }
  .container, [class*="col-"] {
    padding: 0 15px;
  }
  html {
    font-size: 0.84vW
  }
  .mainnav nav ul:not(.layernav) > li.divider {
    padding: 30px 2rem;
  }
}

@media only screen and (max-width: 999px) {
  html {
    font-size: 11px;
  }
  .row {
    //margin: 0 -30px;
  }
  .container, [class*="col-"] {
    //padding: 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  main ul, main ol {
    margin: 0 0 1rem !important;
  }
  section, main > section, main > article {
    padding: 5em 0 !important;
  }
  .twoCol article, .twoCol section {
    margin-bottom: 5em !important;
  }
  section.eventSlider.col3Box {
    padding-bottom: 10em !important;
  }
  .scrollTop {
    transform: scale(0.55);
    right: 0;
    bottom: 0;
  }
  .row.centered {
    text-align: center;
    .col-lg-24, .col-md-24, .col-sm-24, .col-xs-24 {
      margin-right: 0;
      width: 100%;
      display: block;
      float: none;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/
@media only screen and (max-width: 600px) {
  html {
    //font-size: 8.8px;
  }
  main {
  }
}

@media only screen and (min-width: 1050px) {
  .container {
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    width: calc(100% - 200px);
  }
}

@media only screen and (max-width: 999px) {
  .col-md-6 + .col-md-6, aside {
    margin-top: 5rem;
  }
}
